.toggle input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  background: #e6e6e6;
  transition: all 0.2s ease-out;
}

.toggle input:checked[type='checkbox'] {
  background: #ff3250;
}

.toggle input[type='checkbox']::before {
  content: '';
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: white;
  position: relative;
  top: -4px;
  left: -2px;
  float: left;
  transition: all 0.2s ease-out;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  transform: scale(0.9);
}
.toggle input:checked[type='checkbox']::before {
  left: 24px;
}
